import React, { useMemo, useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col, Form, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Layout, Seo } from '../components';
import { Industry, ProgramItemType } from '../utils';
import styles from './bedrijven.module.css';
import formStyles from '../components/formComponents/formComponents.module.css';
import { SelectValue, OptionType } from 'antd/lib/select';
import { useHashParam } from '../hooks';

interface QueryResult {
  strapi: {
    programItemTypes: [ProgramItemType],
    industries: [Industry],
  },
};

export const query = graphql`
  query {
    strapi {
      programItemTypes {
        id
        name
      }
      industries(sort: "title", where: { companies: { programItems: { tbEvent: { active: true } } } }) {
        id
        title
        companies(sort: "name", where: { programItems: { tbEvent: { active: true } } }) {
          id
          name
          slug
          logo {
            url
          }
          programItems(where: { tbEvent: { active: true } }) {
            programItemType {
              id
            }
          }
        }
      }

    }
  }
`;

const BedrijvenPage = ({ data }: { data: QueryResult }) => {
  const { programItemTypes, industries } = data.strapi;

  let type: string | null = null;
  if (typeof window !== "undefined") {
    [type] = useHashParam('type', '-1');
    useEffect(() => {
      setActiveProgramItemType(type as string)
    }, [type]);
  }
  const [activeProgramItemType, setActiveProgramItemType] = useState<SelectValue>(type || '-1');

  const programItemTypeOptions = useMemo(() => {
    return programItemTypes.reduce((types, type) => {
      const { id, name } = type;
      return [...types, { label: name, value: id }];
    }, [{ label: 'Alle onderdelen', value: '-1' }]);
  }, [programItemTypes]);

  const filterCompanies = (activeProgramItemType: SelectValue) => {
    return (industry: Industry): Industry => ({
      ...industry,
      companies: industry.companies.filter((company) => {
        return company.programItems.some(item => item.programItemType.id === activeProgramItemType)
      })
    })
  }

  const industriesWithoutCompanies = (industry: Industry) => {
    return industry.companies.length > 0;
  }

  const filteredIndustries = useMemo(() => {
    if (activeProgramItemType === '-1') return industries;

    return industries
            .map(filterCompanies(activeProgramItemType))
            .filter(industriesWithoutCompanies)
  }, [industries, activeProgramItemType])

  return (
    <Layout>
      <Seo title="Bedrijven" />
      <section className={styles.companies}>
        <div className="container">
          <h2>Deelnemende bedrijven</h2>
          <p>
            Hieronder vind je de bedrijven die deelnemen aan het Techniek Bedrijven Event, en
            bedrijven die Inhousedagen organiseren. Klik op de bedrijventegels voor meer informatie
            en kijk of jullie een potentiële match zijn!
          </p>

          <div style={{ maxWidth: 400 }}>
            <Form className={formStyles.form}>
              <Form.Item>
                <Select
                  defaultValue={activeProgramItemType}
                  options={programItemTypeOptions}
                  suffixIcon={<CaretDownOutlined style={{ color: '#fff', fontSize: 25, top: -7, left: -15, position: 'relative' }} />}
                  onChange={setActiveProgramItemType}
                  />
              </Form.Item>
            </Form>
          </div>

          {filteredIndustries.map(industry => (
            industry.companies.length > 0 && <React.Fragment key={`industry-${industry.id}`}>
              <h3><span className="turquoise">{industry.title}</span></h3>
              <Row  gutter={32}>
                {industry.companies.map(company => (
                  <Col key={`company-${company.id}`} xs={12} md={6}>
                    <Link to={`/bedrijven/${company.slug}`}>
                      <figure className={styles.companyTile}>
                        <img src={company.logo.url} alt={company.name} />
                      </figure>
                    </Link>
                  </Col>
                ))}
              </Row>
            </React.Fragment>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default BedrijvenPage;
